import React from 'react'
import classNames from 'classnames'

import { ButtonDefault, LinkButtonCustom } from '@tellimer/ui/Button'

import { StepperType } from 'types/onboarding.types'
import { selectPaymentsSettings } from 'redux/payments/selector'
import { useAppSelector } from 'hooks/reduxHooks'
import { useRouter } from 'next/router'
import { Divider } from '@tellimer/ui'

export const StripeSetup = ({ skip, variant }: { skip?: () => void; variant?: StepperType }) => {
  const settings = useAppSelector(selectPaymentsSettings)
  const router = useRouter()
  const isOnboardingStepper = router.pathname === '/'

  return (
    <div className="space-y-4">
      <div className="p-6 rounded-lg bg-gray-50">
        <p className="text-lg font-medium text-gray-900">Set up payments</p>
        <p className="mt-2 text-sm text-gray-500 max-w-[36rem]">
          In order to accept payments, you need to setup Stripe Connect. You’ll be directed to setup
          an account with Stripe, which should take around 5 minutes.
        </p>
        <LinkButtonCustom
          className={classNames(
            'w-full md:w-auto justify-center h-9.5 mt-5 text-white bg-stripe-500 hover:bg-stripe-400 focus:ring-stripe-400',
            { 'lg:self-start': variant === StepperType.PRIMARY },
            { 'w-full': isOnboardingStepper && variant === StepperType.SECONDARY },
          )}
          href={settings?.stripeUrl}
          data-testid="conect-stripe-button"
        >
          Connect with Stripe
        </LinkButtonCustom>
        <Divider text="" className="my-5" />
        <p className="text-sm text-gray-500 leading-5 font-normal">
          Want to learn more about setting up payments?{' '}
          <a
            href="https://tellimer.notion.site/How-to-set-up-payments-dbee3a4d2f114713bb026dabb712bba5"
            target="_blank"
            rel="noreferrer"
            className="font-medium text-accent-600 hover:text-accent-700 cursor-pointer"
          >
            Check out our payments resource guide
          </a>
        </p>
      </div>
      <div
        className={classNames('flex flex-col gap-4', {
          'flex-col items-stretch md:items-center md:flex-row': variant === StepperType.PRIMARY,
        })}
      >
        {isOnboardingStepper && (
          <ButtonDefault className="h-9.5" onClick={skip}>
            Skip this step
          </ButtonDefault>
        )}
      </div>
    </div>
  )
}

StripeSetup.displayName = 'StripeSetup'
