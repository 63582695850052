import React from 'react'
import { useFormContext } from 'react-hook-form'

import { Input, InputGroup } from '@tellimer/ui/Input'
import { Info, Toggle } from '@tellimer/ui'
import { StepperType } from 'types/onboarding.types'
import { SubscriptionDetails } from 'types/payment.types'
import { SubscriptionAnnualDiscount } from './SubscriptionAnnualDiscount'
import { useAppSelector } from 'hooks/reduxHooks'
import { selectPaymentsHasPaidSubscribers } from 'redux/payments/selector'
import { Tooltip } from '@tellimer/ui/Tooltip'

export const SubscriptionTier = ({
  variant = StepperType.PRIMARY,
  index,
  details,
}: {
  variant?: StepperType
  details: SubscriptionDetails
  index: number
}) => {
  const hasPaidSubscribers = useAppSelector(selectPaymentsHasPaidSubscribers)

  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext()

  const isEnabled = watch(`gettingPaid.${index}.isEnabled`)
  const monthlyPrice = watch(`gettingPaid.${index}.monthlyPrice`)

  const formErrors = (errors.gettingPaid || [])[index] || {}

  return (
    <div className="flex flex-col gap-y-6 flex-wrap p-6 rounded-lg border border-gray-200 bg-white">
      <p className="text-lg text-gray-900">{details.name}</p>

      {!details.isFree ? (
        <div className="flex items-center">
          <Toggle
            className="mr-3"
            onToggle={(value: boolean) => setValue(`gettingPaid.${index}.isEnabled`, value)}
            checked={!!isEnabled}
            disabled={hasPaidSubscribers}
            title={'To disable a paid tier with active paid subscribers contact support@scriber.to'}
          />

          <div className="flex-1">
            <span className="text-sm font-medium text-gray-900">Enable {details.name}</span>
            {details.isRecommended ? (
              <span className="text-gray-400 font-regular ml-1">
                <br />
                (Recommended)
              </span>
            ) : null}

            {hasPaidSubscribers ? (
              <Tooltip
                className="ml-2"
                content={
                  <p className="text-white text-xs leading-4 font-medium">
                    To disable a paid tier with active paid subscribers contact support@scriber.to
                  </p>
                }
              >
                <Info className="w-5 text-gray-400 inline-block" />
              </Tooltip>
            ) : null}
          </div>
        </div>
      ) : null}

      {!details.isFree ? (
        <>
          <div>
            <InputGroup
              label="Set your monthly price"
              hasError={formErrors.monthlyPrice !== undefined}
              errorMsg={formErrors.monthlyPrice?.message}
            >
              <Input
                type="number"
                prefix="$"
                placeholder="8.00"
                min={5}
                {...register(`gettingPaid.${index}.monthlyPrice`, {
                  valueAsNumber: true,
                  min: 5,
                })}
                hasError={formErrors.monthlyPrice}
                disabled={!isEnabled}
              />
            </InputGroup>
          </div>
          <div>
            <label className="text-sm font-medium text-gray-700">
              Add discount for annual subscriptions?
            </label>
            <div className="space-y-2 flex-wrap mt-1">
              <SubscriptionAnnualDiscount
                discounts={details.discounts || []}
                onChange={({ name, value }: { name: string; value?: number | null }) => {
                  const selectedDiscount = (details.discounts || []).find(
                    discount => discount.name === name,
                  )
                  setValue(
                    `gettingPaid.${index}.annualDiscount`,
                    { name, value: selectedDiscount?.isCustom ? value : selectedDiscount?.value },
                    {
                      shouldValidate: true,
                    },
                  )
                }}
                index={index}
                variant={variant}
                disabled={!monthlyPrice || !isEnabled}
                hasError={formErrors.annualDiscount !== undefined}
                errorMsg={formErrors.annualDiscount?.value.message}
              />
            </div>
          </div>
        </>
      ) : null}

      <div>
        <InputGroup
          label={`${details.name} description`}
          hasError={formErrors.description !== undefined}
          errorMsg={formErrors.description?.message}
        >
          <Input
            type="text"
            placeholder={details.isFree ? 'E.g. Regular public posts' : 'E.g. Premium posts'}
            {...register(`gettingPaid.${index}.description`)}
            hasError={formErrors.description}
            disabled={!isEnabled}
          />
        </InputGroup>
      </div>
    </div>
  )
}

SubscriptionTier.displayName = 'SubscriptionTier'
