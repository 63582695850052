import { AppState } from '../store'

export const selectPaymentsAreLoading = (state: AppState) => state.payments.isLoading
export const selectPaymentsInfo = (state: AppState) => state.payments
export const selectPaymentsSettings = (state: AppState) => state.payments.settings
export const selectPaymentsTypes = (state: AppState) => state.payments.types
export const selectIsSettingUpStripe = (state: AppState) => state.payments.isSettingUpStripe
export const selectPaymentsIsSaving = (state: AppState) => state.payments.isSaving
export const selectPaymentsHasPaidSubscribers = (state: AppState) =>
  state.payments.hasPaidSubscribers
