import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'
import classNames from 'classnames'
import { yupResolver } from 'helper/yupResolver'

import { ButtonDefault, ButtonSecondary, LinkButtonDefault } from '@tellimer/ui/Button'
import { NotificationLayout, NotificationManager } from '@tellimer/ui/Notification'
import { Stripe } from '@tellimer/ui/Icon'

import { useAppDispatch } from 'redux/store'
import { completeOnboardingStep } from 'redux/onboardingProgress/slice'
import {
  selectIsSettingUpStripe,
  selectPaymentsAreLoading,
  selectPaymentsIsSaving,
  selectPaymentsSettings,
  selectPaymentsTypes,
} from 'redux/payments/selector'
import { saveSubscriptionPackages } from 'redux/payments/actions'
import { useAppSelector } from 'hooks/reduxHooks'
import { OnboardingStepTypes, StepperType } from 'types/onboarding.types'

import { Divider, LoadingBar } from '@tellimer/ui'
import { SubscriptionType } from 'types/payment.types'
import { SubscriptionTier } from './SubscriptionTier'
import { useRouter } from 'next/router'

type SubscriptionFormValue = {
  type: SubscriptionType
  isEnabled: boolean
  isFree?: boolean
  monthlyPrice?: number
  annualDiscount?: {
    name: string
    value: number
  }
  description?: string
}

type FormValues = { gettingPaid: SubscriptionFormValue[] }

const fieldRequiredMessage = 'This field is required.'
const fieldNumberMessage1 = 'Your monthly price must be greater than or equal to $5'
const fieldNumberMessage2 = 'This field must be a valid number between 0 and 50'

const formSchema = yup.object({
  gettingPaid: yup.array().of(
    yup.object().shape({
      isEnabled: yup.boolean(),
      isFree: yup.boolean(),
      monthlyPrice: yup
        .number()
        .transform(value => (isNaN(value) ? undefined : value))
        .nullable()
        .when(['isEnabled', 'isFree'], {
          is: (isEnabled: boolean, isFree: boolean) => isEnabled && !isFree,
          then: yup
            .number()
            .positive(fieldNumberMessage1)
            .typeError(fieldNumberMessage1)
            .min(5, fieldNumberMessage1)
            .required(fieldRequiredMessage),
        }),
      annualDiscount: yup
        .object()
        .nullable()
        .when(['monthlyPrice', 'isEnabled', 'isFree'], {
          is: (monthlyPrice: number, isEnabled: boolean, isFree: boolean) =>
            isEnabled && !isFree && monthlyPrice > 0,
          then: yup.object().shape({
            name: yup.string().nullable(),
            value: yup
              .number()
              .positive(fieldNumberMessage2)
              .typeError(fieldNumberMessage2)
              .min(0, fieldNumberMessage2)
              .max(0.5, fieldNumberMessage2)
              .required(fieldRequiredMessage),
          }),
        }),
      description: yup.string().when(['isEnabled'], {
        is: (isEnabled: boolean) => isEnabled,
        then: yup.string().required(fieldRequiredMessage),
      }),
    }),
  ),
})

export const SubscriptionTierList = ({
  skip,
  step,
  variant,
}: {
  step?: OnboardingStepTypes
  skip?: () => void
  variant?: StepperType
}) => {
  const dispatch = useAppDispatch()

  const settings = useAppSelector(selectPaymentsSettings)
  const isLoading = useAppSelector(selectPaymentsAreLoading)
  const isSettingUpStripe = useAppSelector(selectIsSettingUpStripe)
  const isSaving = useAppSelector(selectPaymentsIsSaving)
  const paymentTypes = useAppSelector(selectPaymentsTypes)
  const router = useRouter()
  const isSettingsPage = router.pathname === '/settings/[slug]'

  const methods = useForm<FormValues>({
    resolver: yupResolver(formSchema),
  })

  const onSubmit = async () => {
    try {
      const { gettingPaid } = methods.getValues()
      await dispatch(saveSubscriptionPackages(gettingPaid)).unwrap()
      if (step) {
        dispatch(completeOnboardingStep(step))
      }
      if (isSettingsPage) {
        NotificationManager.create({
          type: 'success',
          layout: NotificationLayout.CONDENSED,
          title: 'Subscription settings were successfully saved',
        })
      }
    } catch (err) {
      console.error(err)
      NotificationManager.create({
        type: 'error',
        layout: NotificationLayout.CONDENSED,
        title: 'Something failed while saving the subscriptions',
      })
    }
  }

  useEffect(() => {
    if (isLoading || isSettingUpStripe) return

    methods.reset({
      gettingPaid: (paymentTypes || []).map(payment => {
        const data: SubscriptionFormValue = {
          type: payment.type,
          isFree: payment.isFree,
          isEnabled: payment.isFree ? true : payment.isEnabled,
          description: payment.description,
        }

        if (!payment.isFree) {
          data.monthlyPrice = payment.price

          const selectedDiscount = (payment.discounts || []).find(discount => discount.isSelected)

          if (selectedDiscount) {
            data.annualDiscount = {
              name: selectedDiscount.name,
              value: selectedDiscount.value,
            }
          }
        }

        return data
      }),
    })
  }, [isLoading, isSettingUpStripe])

  return (
    <FormProvider {...methods}>
      <div
        className={
          isSettingsPage
            ? `flex flex-col gap-y-6 flex-wrap p-6 rounded-lg border border-gray-200 bg-white`
            : ''
        }
      >
        {isSettingsPage && <p className="text-lg font-medium text-gray-900">Set up payments</p>}
        <div
          className={classNames('flex gap-x-3 gap-y-4 flex-wrap p-6 rounded-md bg-gray-50', {
            'lg:flex-row lg:items-center lg:justify-between': variant === StepperType.PRIMARY,
            'flex-col': !isSettingsPage,
          })}
        >
          <Stripe
            className={classNames('fill-stripe-500', {
              'lg:block': variant === StepperType.PRIMARY,
              'hidden shrink-0': !isSettingsPage,
            })}
          />
          <div
            className={classNames('grow shrink-0', {
              'lg:basis-52': variant === StepperType.PRIMARY,
            })}
          >
            <p className="text-sm font-medium text-gray-900">Your Stripe account is connected</p>
            <p className="mt-1 text-sm text-gray-500 break-all">
              Account: <span className="font-medium">{settings?.accountName}</span>
            </p>
          </div>
          <div
            className={classNames('flex gap-4 items-center', {
              'flex-row gap-2': isSettingsPage,
              'flex-col': !isSettingsPage,
            })}
          >
            <LinkButtonDefault
              className={classNames('shrink-0 h-9.5 text-center', {
                'w-auto': isSettingsPage,
                'w-full': !isSettingsPage,
              })}
              href="https://dashboard.stripe.com"
              target="_blank"
            >
              Stripe dashboard
            </LinkButtonDefault>
          </div>
          <div className="w-full">
            <Divider text="" className="mb-5" />
            <p className="text-sm text-gray-500 leading-5 font-normal">
              Want to learn more about setting up payments?{' '}
              <a
                href="https://tellimer.notion.site/How-to-set-up-payments-dbee3a4d2f114713bb026dabb712bba5"
                target="_blank"
                rel="noreferrer"
                className="font-medium text-accent-600 hover:text-accent-700 cursor-pointer"
              >
                Check out our payments resource guide
              </a>
            </p>
          </div>
        </div>
      </div>
      <form
        className="flex flex-col gap-y-4 lg:gap-y-6 mt-4 lg:mt-6"
        onSubmit={methods.handleSubmit(onSubmit)}
        noValidate
      >
        {(paymentTypes || []).map((entry, index) => {
          return (
            <SubscriptionTier key={entry.type} index={index} variant={variant} details={entry} />
          )
        })}

        {isSettingsPage ? (
          <ButtonSecondary className="self-start h-9.5" type="submit">
            {isSaving && <LoadingBar size="sm" className="mr-1" />} Save
          </ButtonSecondary>
        ) : (
          <div
            className={classNames('flex flex-col gap-4', {
              'flex-col items-stretch md:items-center md:flex-row': variant === StepperType.PRIMARY,
            })}
          >
            <ButtonSecondary
              className="h-9.5"
              type="submit"
              disabled={isSaving}
              data-testid="payments-step-save-btn"
            >
              {isSaving && <LoadingBar size="sm" className="mr-1" />} Save and continue
            </ButtonSecondary>
            <ButtonDefault
              className="h-9.5"
              onClick={skip}
              disabled={isSaving}
              data-testid="publication-step-skip-step-btn"
            >
              Skip this step
            </ButtonDefault>
          </div>
        )}
      </form>
    </FormProvider>
  )
}

SubscriptionTierList.displayName = 'SubscriptionTierList'
