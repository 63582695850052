import React, { Ref, useEffect, useState } from 'react'
import { Input } from '@tellimer/ui/Input'

type Props = {
  onChange: (value?: number | null) => void
  value?: number
  placeholder?: string
  min?: number
  max?: number
  disabled?: boolean
}

export const InputPercentage = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { value, onChange, placeholder, min, max, disabled, ...rest } = props
  const [stringValue, setStringValue] = useState(value ? (value * 100).toFixed(0) : null)

  useEffect(() => {
    setStringValue(value ? (value * 100).toFixed(0) : '')
  }, [value])

  return (
    <Input
      ref={ref as Ref<HTMLInputElement>}
      type="number"
      suffix="%"
      value={stringValue}
      min={min || 0}
      max={max || 100}
      placeholder={placeholder}
      onChange={({ target }: { target: HTMLInputElement }) => {
        const { value } = target

        const numericValue = Number(value)
        onChange(!isNaN(numericValue) ? numericValue / 100 : null)
      }}
      disabled={disabled}
      {...rest}
    />
  )
})
