/**
 * This is the formatter used when the required options are not supported or there is no Intl at all
 */
const NOOP_CURRENCY_FORMATTER = { format: (value: Number) => '$' + value }
const NOOP_DATE_FORMATTER = { format: (value: Date) => value.toISOString() }

// We cache the formatters so we don't create them every time, it can get expensive on React
const numberFormatters = new Map()

const getNumberFormatter = (options: Intl.NumberFormatOptions) => {
  if (numberFormatters.get(options)) {
    numberFormatters.get(options)
  }

  let formatter

  try {
    formatter = new Intl.NumberFormat('default', options)
  } catch (e) {
    console.warn('Failed to create a number formatter for options:', options)
    formatter = NOOP_CURRENCY_FORMATTER
  }

  numberFormatters.set(options, formatter)

  return formatter
}

const DEFUALT_CURRENCY_OPTIONS: Intl.NumberFormatOptions = {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'narrowSymbol',
}

export const formatCurrency = (value: number, options?: Intl.NumberFormatOptions) => {
  const formatter = getNumberFormatter(options || DEFUALT_CURRENCY_OPTIONS)
  return formatter.format(value)
}

const dateFormatters = new Map()

const getDateFormatter = (options: Intl.DateTimeFormatOptions) => {
  if (dateFormatters.get(options)) {
    dateFormatters.get(options)
  }

  let formatter

  try {
    formatter = new Intl.DateTimeFormat('default', options)
  } catch (e) {
    console.warn('Failed to create a date formatter for options:', options)
    formatter = NOOP_DATE_FORMATTER
  }

  dateFormatters.set(options, formatter)

  return formatter
}

const DEFAULT_DATE_OPTIONS: Intl.DateTimeFormatOptions = { dateStyle: 'medium' }

export const formatDate = (value: Date, options?: Intl.DateTimeFormatOptions) => {
  const formatter = getDateFormatter(options || DEFAULT_DATE_OPTIONS)
  return formatter.format(value)
}
