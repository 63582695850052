import React from 'react'
import classNames from 'classnames'

import { RadioGroup } from '@tellimer/ui/headless'
import { ExclamationCircleIcon } from '@tellimer/ui/heroicons/solid'
import { StepperType } from 'types/onboarding.types'
import { SubscriptionTypeDiscount } from 'types/payment.types'
import { useFormContext } from 'react-hook-form'
import { InputPercentage } from 'components/common/InputPercentage'
import { formatCurrency } from 'helper/format'

type Props = {
  discounts: SubscriptionTypeDiscount[]
  onChange: ({ name, value }: { name: string; value?: number | null }) => void
  value?: { name: string | null; value: number }
  variant?: StepperType
  disabled?: boolean
  hasError?: boolean
  errorMsg?: string
  index: number
}

export const SubscriptionAnnualDiscount = ({
  onChange,
  variant = StepperType.PRIMARY,
  disabled = false,
  hasError = false,
  errorMsg,
  discounts,
  index,
}: Props) => {
  const classes = {
    group: classNames('flex flex-col gap-2 flex-wrap mt-1 w-full', {
      'lg:flex-row lg:items-center': variant == StepperType.PRIMARY,
    }),
    option: (checked: boolean) =>
      classNames(
        'flex justify-center items-center h-9.5 rounded-[4px] text-sm font-medium text-center border cursor-pointer',
        {
          'grow lg:w-[80px]': variant == StepperType.PRIMARY,
          'bg-accent-600 text-white border-transparent': checked && !disabled && !hasError,
          'bg-white border-gray-300': !checked && !disabled && !hasError,
          'bg-gray-50 border-gray-300 cursor-default': disabled,
          'border-red-500': hasError,
        },
      ),
    label: (checked: boolean) =>
      classNames('font-medium', {
        'text-white': checked && !disabled && !hasError,
        'text-gray-900': !checked && !disabled && !hasError,
        'text-gray-300': disabled,
        'text-red-900': hasError,
      }),
  }

  const { watch } = useFormContext()

  const annualDiscount = watch(`gettingPaid.${index}.annualDiscount`)
  const monthlyPrice = watch(`gettingPaid.${index}.monthlyPrice`)

  const annualPriceNumber = isNaN(monthlyPrice) ? 0 : monthlyPrice * 12
  const annualPrice = formatCurrency(annualPriceNumber)
  const annualPriceDiscounted = formatCurrency(
    annualPriceNumber * Math.max(Math.min(1 - annualDiscount?.value, 1), 0),
  )
  const hasAnnualDiscount = annualDiscount?.value > 0 && annualPrice !== annualPriceDiscounted

  return (
    <>
      <RadioGroup
        value={annualDiscount?.name}
        onChange={(name: string) => onChange({ name })}
        className={classes.group}
        disabled={disabled}
      >
        {(discounts || []).map(({ name, isCustom }) => {
          if (isCustom) {
            return (
              <div key={name} className="grow">
                <InputPercentage
                  value={annualDiscount?.name === name ? annualDiscount?.value : ''}
                  onChange={value => onChange({ name, value })}
                  placeholder="Other"
                  min={0}
                  max={50}
                  disabled={disabled}
                />
              </div>
            )
          }

          return (
            <RadioGroup.Option
              key={name}
              value={name}
              className={({ checked }) => classes.option(checked)}
            >
              {({ checked }) => (
                <RadioGroup.Label as="p" className={classes.label(checked)}>
                  {name}
                </RadioGroup.Label>
              )}
            </RadioGroup.Option>
          )
        })}
      </RadioGroup>
      {hasError && errorMsg ? (
        <p className={'flex items-center mt-2 text-sm text-red-600'}>
          <ExclamationCircleIcon
            className="shrink-0 h-5 w-5 mr-1 text-red-500"
            aria-hidden="true"
          />{' '}
          {errorMsg}
        </p>
      ) : null}
      {annualPrice ? (
        <div
          className={classNames(
            'flex flex-col justify-center lg:justify-start items-center shrink-0 w-full p-3 bg-gray-50',
            { 'lg:flex-row': variant === StepperType.PRIMARY },
          )}
        >
          <span className="lg:mr-2 font-medium text-center text-gray-700">Your annual price:</span>
          <div className="mt-1">
            {hasAnnualDiscount ? (
              <span className="text-gray-500 line-through">{annualPrice} </span>
            ) : null}
            <span className="text-gray-700">
              {hasAnnualDiscount ? annualPriceDiscounted : annualPrice}
            </span>
          </div>
        </div>
      ) : null}
    </>
  )
}

SubscriptionAnnualDiscount.displayName = 'SubscriptionAnnualDiscount'
